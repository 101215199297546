export let API_URL = "API_URL_REPLACE_ME";
export let ATUALIZACAO_CADASTRAL_URL = "ATUALIZACAO_CADASTRAL_URL_REPLACE_ME";
export let CONSULTA_CADASTRO_URL = "CONSULTA_CADASTRO_URL_REPLACE_ME";
export let CODE_GA = "CODE_GA_REPLACE_ME"

if (process.env.NODE_ENV === "development") {
  API_URL = process.env.REACT_APP_API_URL;
  ATUALIZACAO_CADASTRAL_URL = process.env.REACT_APP_ATUALIZACAO_CADASTRAL_URL;
  CONSULTA_CADASTRO_URL = process.env.REACT_APP_CONSULTA_CADASTRO_URL;
  CODE_GA = process.env.REACT_APP_CODE_GA
}
