export const ORDENAR_OPCOES_KIT = [
  {
    uuid: "distancia",
    nome: "Menor distância",
  },
  {
    uuid: "total_materiais",
    nome: "Menor preço de kit",
  },
  {
    uuid: "nome_fantasia",
    nome: "Ordem alfabética",
  },
];
