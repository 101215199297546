export const OPCOES_MATERIAIS = [
  {
    nome: "Kits de material escolar",
    uuid: "kits",
  },
  {
    nome: "Itens de material escolar avulsos",
    uuid: "itens",
  },
];
